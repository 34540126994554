// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-book-a-flight-index-js": () => import("./../../../src/templates/book-a-flight/index.js" /* webpackChunkName: "component---src-templates-book-a-flight-index-js" */),
  "component---src-templates-company-index-js": () => import("./../../../src/templates/company/index.js" /* webpackChunkName: "component---src-templates-company-index-js" */),
  "component---src-templates-contact-us-index-js": () => import("./../../../src/templates/contact-us/index.js" /* webpackChunkName: "component---src-templates-contact-us-index-js" */),
  "component---src-templates-flight-js": () => import("./../../../src/templates/flight.js" /* webpackChunkName: "component---src-templates-flight-js" */),
  "component---src-templates-gallery-index-js": () => import("./../../../src/templates/gallery/index.js" /* webpackChunkName: "component---src-templates-gallery-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-impressum-index-js": () => import("./../../../src/templates/impressum/index.js" /* webpackChunkName: "component---src-templates-impressum-index-js" */),
  "component---src-templates-instructions-index-js": () => import("./../../../src/templates/instructions/index.js" /* webpackChunkName: "component---src-templates-instructions-index-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-pricing-index-js": () => import("./../../../src/templates/pricing/index.js" /* webpackChunkName: "component---src-templates-pricing-index-js" */),
  "component---src-templates-single-news-js": () => import("./../../../src/templates/single-news.js" /* webpackChunkName: "component---src-templates-single-news-js" */),
  "component---src-templates-terms-conditions-index-js": () => import("./../../../src/templates/terms-conditions/index.js" /* webpackChunkName: "component---src-templates-terms-conditions-index-js" */)
}

